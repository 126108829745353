import React, { useEffect, useMemo } from 'react'
import PersonInfoModal from './modals/PersonInfoModal'
import './App.css'
import {
  useGetPeopleDataQuery,
  useGetFamilyDataQuery,
  useGetBranchDataQuery,
  useGetResidenceDataQuery,
  useGetColorDataQuery,
} from './redux/slices/api/apiSlice'
import AppBar from './components/AppBar'
import Tree from './components/Layout/Tree'
import Loading from './components/Loading'
import createTreeData from './utils/createTreeData'

function App() {
  const [hasFetched, setHasFetched] = React.useState(false)

  const {
    data: peopleRes,
    error: peopleError,
    isFetching: peopleIsFetching,
  } = useGetPeopleDataQuery()

  const {
    data: familiesRes,
    error: familiesError,
    isFetching: familiesIsFetching,
  } = useGetFamilyDataQuery()

  const {
    data: branchesRes,
    error: branchesError,
    isFetching: branchesIsFetching,
  } = useGetBranchDataQuery()

  const {
    data: residencesRes,
    error: residencesError,
    isFetching: residencesIsFetching,
  } = useGetResidenceDataQuery()

  const {
    data: colorsRes,
    error: colorsError,
    isFetching: colorsIsFetching,
  } = useGetColorDataQuery()

  const isFetching =
    peopleIsFetching ||
    familiesIsFetching ||
    residencesIsFetching ||
    colorsIsFetching ||
    branchesIsFetching

  const error =
    peopleError ||
    familiesError ||
    branchesError ||
    residencesError ||
    colorsError

  useEffect(() => {
    console.log('030725-1')
  }, [])

  useEffect(() => {
    if (isFetching) {
      setHasFetched(true)
    }
  }, [isFetching])

  useEffect(() => {
    let status = 0
    if (error) {
      if ('status' in error) {
        status = error.status as number
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        if ('response' in error && 'status' in error.response) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          status = error.response.status
        }
      }
    }
    console.log('STATUS: ', status)
    if (hasFetched && status === 401) {
      window.localStorage.setItem('treeRedirectUrl', window.location.href)
      window.location.replace(
        process.env.REACT_APP_AUTH_ERROR_REDIRECT_URL || '',
      )
    }
  }, [error, hasFetched])

  const data = useMemo(() => {
    if (
      peopleRes?.length &&
      familiesRes?.length &&
      branchesRes?.length &&
      residencesRes?.length &&
      colorsRes?.length
    ) {
      return createTreeData(
        peopleRes,
        familiesRes,
        branchesRes,
        residencesRes,
        colorsRes,
      )
    } else {
      return null
    }
  }, [peopleRes, familiesRes, branchesRes, residencesRes, colorsRes])

  console.log('STAGE: ', process.env.REACT_APP_STAGE)

  return (
    <div>
      <AppBar data={data} />
      {data ? (
        <div>
          <Tree
            people={data.people}
            families={data.families}
            branches={data.branches}
          />
          <PersonInfoModal people={data.people} families={data.families} />
        </div>
      ) : (
        <Loading />
      )}
    </div>
  )
}

export default App
